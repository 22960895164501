import { FC, useState, useEffect, useMemo, Fragment } from 'react';
import Modal from '../templates/Modal';
import { useCopyToClipboard, useRFS, useUIStore } from 'utils/hooks';
import Grid from '@mui/material/Grid';
import PatientForm from 'components/forms/Patient';
import { ToggleSection, ToggleSections, ServiceRequestFiles } from 'components';
import { MedicalApi, ZrefApi, DocumentApi } from 'apis';
import { ServiceRequestType, CompositionType, FacilityType, GoalType } from 'apis/medical';
import moment from 'moment';
import { RFSReviewProps } from './RFSReview.types';
import styles from './style.module.scss';
import RFSReviewHeader from './RFSReviewHeader';
import { toast } from 'react-toastify';
import { DuplicateCopyBoldIcon } from '@zeel-dev/zeel-ui';
import { createErrorMessage } from 'utils/helper';
import { RequestType } from 'apis/zref';

const CLINICAL_JUSTIFICATION_MAP = {
  continued_improvement: 'Patient is expected to see continued progress toward their goals from continued treatment.',
  regression_mitigation:
    // eslint-disable-next-line max-len
    'Patient has demonstrated progress in their function and objective findings as a result of treatment but due to the chronic nature of their condition further significant improvement will be challenging. Treatment is indicated to maintain their current level of function and to prevent a deterioration of symptoms.',
};

const RFSReview: FC<RFSReviewProps> = ({ requestId, onClose, scrollTop, modalProps }) => {
  const { generateRFSPackageBlob } = useRFS();
  const { openAlert, openModal } = useUIStore();
  const { copyToClipboard } = useCopyToClipboard();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [request, setRequest] = useState<RequestType>();
  const [serviceRequest, setServiceRequest] = useState<ServiceRequestType>();
  const [facility, setFacility] = useState<FacilityType>();
  const [notes, setNotes] = useState<CompositionType[]>();
  const [requestLookup, setRequestLookup] = useState<any>();
  const [goals, setGoals] = useState<Array<GoalType>>();

  const goalsMet = useMemo(() => {
    return goals?.filter((g) => g.goal_status === 'completed');
  }, [goals]);

  const goalsInProgress = useMemo(() => {
    return goals?.filter((g) => ['proposed', 'planned', 'accepted', 'active', 'on-hold'].includes(g.goal_status));
  }, [goals]);

  useEffect(() => {
    async function fetchData() {
      try {
        const [rfs, lookup] = await Promise.all([
          ZrefApi.fetchRequest(requestId),
          ZrefApi.fetchRequestLookup(requestId),
        ]);
        setRequest(rfs);
        setRequestLookup(lookup);

        const [f, sr] = await Promise.all([
          MedicalApi.fetchFacility(rfs.facility_id),
          MedicalApi.fetchServiceRequest(lookup.initiating_service_request.id),
        ]);

        setFacility(f);
        setServiceRequest(sr);

        const _goals = await MedicalApi.getGoals(sr?.patient_id);
        setGoals(_goals);

        const promises = lookup.relevant_compositions.map(({ id }) => MedicalApi.fetchSoapNote(id));
        const data: any = await Promise.allSettled(promises);
        const _notes = data
          .filter((p: { status: string; value: any }) => p.status === 'fulfilled' && p.value)
          .map((p: { status: string; value: any }) => p.value);
        setNotes(_notes);
      } catch (e) {
        setError('An error occurred while fetching the request or the soap notes');
      }
      setLoading(false);
    }
    fetchData();
  }, [requestId]);

  const approveRequest = async () => {
    if (!request) {
      return;
    }

    openModal({
      id: 'rfs-snooze',
      callback: async (res?: { approve: boolean; holdUntilDate?: string }) => {
        if (res) {
          setLoading(true);

          try {
            await ZrefApi.updateRequest(requestId, {
              ...(res?.holdUntilDate ? { hold_until_date: res.holdUntilDate } : {}),
              status: 'accepted',
            });
            openAlert({
              title: `We’ll re-surface this RFS in the Tasks list on ${moment(res.holdUntilDate).format(
                'MMMM DD, YYYY'
              )}.`,
            });
            openAlert({ title: `The request was accepted.` });

            const uploadDocumentToHiOperator = async () => {
              const rfsPackageBlob = await generateRFSPackageBlob(
                request.request_id,
                requestLookup.initiating_service_request.id,
                request.initiating_referral_number
              );

              const response = await DocumentApi.uploadDocumentToHiOperator(rfsPackageBlob).catch((uploadError) => {
                throw new Error(createErrorMessage('Uploading RFS Documents', uploadError));
              });
              const documentUrl = response.location;

              await ZrefApi.updateRequest(requestId, {
                document_url: documentUrl,
              }).catch((updateError) => {
                throw new Error(createErrorMessage('Updating RFS Document URL', updateError));
              });
            };

            toast.promise(
              uploadDocumentToHiOperator,
              {
                pending: 'Generating PDF and uploading to HiOperator...',
                success: {
                  render: () => 'PDF successfully generated and uploaded to HiOperator',
                  autoClose: 3000,
                },
                error: {
                  autoClose: false,
                  closeOnClick: false,
                  render: ({ data }) => (
                    <div>
                      <span>
                        The automatic generation of the RFS package was unsuccessful, please copy this message (
                        referral ID {request.initiating_referral_number}) send it to help@zeel.com, and proceed with the
                        documentation approval.
                      </span>
                      {data.message && <span>{data.message}</span>}
                      <DuplicateCopyBoldIcon
                        size={24}
                        onClick={() => copyToClipboard(`referral ID ${request.initiating_referral_number}`)}
                      />
                    </div>
                  ),
                },
              },
              {
                position: 'bottom-right',
              }
            );

            onClose?.(true);
          } catch (e) {
            setError('An error occurred while accepting the request');
            scrollTop();
            console.error(e);
          }
          setLoading(false);
        }
      },
    });
  };

  const rejectRequest = async () => {
    openModal({
      id: 'reject-rfs',
      props: {
        requestId: requestId,
      },
      callback: (response: any) => {
        if (response) {
          onClose?.(true);
        }
      },
    });
  };

  const serviceRequestsListProp = useMemo(() => {
    return serviceRequest ? [serviceRequest] : undefined;
  }, [serviceRequest]);

  const isOldFlow = !!request?.progress_to_date;
  const isNewFlow = !isOldFlow && goals && goals.length > 0;

  return (
    <Modal
      {...modalProps}
      loading={loading}
      alert={error}
      title={`Review Request for Additional Services + Evaluation`}
      description={<>Please review this provider’s request. Need help? Read the Process Overview</>}
      className={styles.base}
      footerClassName={styles.footer}
      backdropClose={false}
      onSubmit={approveRequest}
      actions={[
        { label: 'Approve Request + Evaluation', action: 'submit' },
        {
          label: 'Reject and Notify Provider',
          state: 'danger',
          className: styles.rejectButton,
          onClick: rejectRequest,
        },
      ]}
      stackActions>
      <RFSReviewHeader loading={loading} serviceRequest={serviceRequest} facility={facility} />
      <Grid className={styles.section} container spacing={3}>
        <Grid item xs={12}>
          <p className={styles.title}>Request for Additional Services</p>
        </Grid>
        <Grid item xs={12} className={styles.description}>
          <br />
          Submitted by {request?.provider.given_name.join(' ')} {request?.provider.family_name} (NPI{' '}
          {request?.provider.individual_practice_npi})
          <br />
          For patient {request?.patient.given_names.join(' ')} {request?.patient.family_name} on{' '}
          {moment(request?.created_at).format('MMMM D')}
        </Grid>
      </Grid>
      <Grid className={styles.section} container spacing={3}>
        <Grid item xs={12}>
          <p className={styles.title}>Number of Additional Sessions</p>
        </Grid>
        <Grid item xs={12} className={styles.description}>
          {request?.number_of_visits} Additional Sessions
        </Grid>
      </Grid>
      {isOldFlow && (
        <Grid className={styles.section} container spacing={3}>
          <Grid item xs={12}>
            <p className={styles.title}>Progress to Date</p>
          </Grid>
          <Grid item xs={12} className={styles.description}>
            {request?.progress_to_date}
          </Grid>
        </Grid>
      )}
      {isNewFlow && (
        <Grid className={styles.section} container spacing={3}>
          <Grid item xs={12}>
            <p className={styles.title}>Clinical Justification</p>
          </Grid>
          <Grid item xs={12} className={styles.description}>
            {request?.clinical_justification && CLINICAL_JUSTIFICATION_MAP[request.clinical_justification]}
          </Grid>
        </Grid>
      )}
      <Grid className={styles.section} container spacing={3}>
        <Grid item xs={12}>
          <p className={styles.title}>Reason for Additional Services</p>
        </Grid>
        <Grid item xs={12} className={styles.description}>
          {request?.reason_for_requested_service_and_scheduling_instructions}
        </Grid>
      </Grid>
      {isNewFlow && (
        <>
          <Grid className={styles.section} container spacing={3}>
            <Grid item xs={12}>
              <p className={styles.title}>Goals met</p>
            </Grid>
            <Grid item xs={12} className={styles.description}>
              {goalsMet?.map((g, gIndex) => (
                <p key={g.id}>
                  Goal {gIndex + 1}: {g['improve_ability']?.[0]?.['improvement_details']}
                </p>
              ))}
            </Grid>
          </Grid>
          <Grid className={styles.section} container spacing={3}>
            <Grid item xs={12}>
              <p className={styles.title}>Goals in progress</p>
            </Grid>
            <Grid item xs={12} className={styles.description}>
              {goalsInProgress?.map((g, gIndex) => (
                <p key={g.id}>
                  Goal {gIndex + 1}: {g['improve_ability']?.[0]?.['improvement_details']} - Progressing toward goal.
                </p>
              ))}
            </Grid>
          </Grid>
        </>
      )}
      <Grid className={styles.section} container>
        <ToggleSection title='Evaluations' disabled={!serviceRequest || loading}>
          {serviceRequest && (
            <ServiceRequestFiles
              serviceRequests={serviceRequestsListProp}
              filter={(d: any) => d?.type !== 'consent'}
              condensed
            />
          )}
        </ToggleSection>
        <ToggleSection title='Prior SOAP Notes' disabled={!notes?.length || loading}>
          <div className={styles.soapNotesContainer}>
            <ToggleSections
              sections={(notes ?? []).map((note: any) => {
                const { id, date_submitted, meta, notes: _notes } = note;
                return {
                  id,
                  icon: 'notes',
                  title: moment(date_submitted || meta?.lastUpdated).format('LLL'),
                  level: 1,
                  borders: true,
                  children: (
                    <div className={styles.noteContent}>
                      {_notes?.map((n: any) => {
                        const { title, text } = n;
                        return (
                          <Fragment key={title}>
                            <p className={styles.noteTitle}>{title}</p>
                            <p className={styles.noteDescription}>{text}</p>
                          </Fragment>
                        );
                      })}
                    </div>
                  ),
                };
              })}
            />
          </div>
        </ToggleSection>
        <ToggleSection title='Additional Information' disabled={loading}>
          {request?.patient && <PatientForm patient={request.patient} marginBottom />}
        </ToggleSection>
      </Grid>
    </Modal>
  );
};

export default RFSReview;
