import api from 'apis';
import { CancelToken } from 'axios';
import { AppointmentStageType, AppointmentType } from '../medical';

export type ZCCSortByParameters = Record<string, 'asc' | 'desc'>;

export interface ZccApiParameters {
  page?: number;
  pageSize?: number;
  filters?: Record<string, any>;
  sortBy?: ZCCSortByParameters;
}

export enum ZccRefererType {
  TRIWEST = 'triwest',
  OPTUM = 'optum',
  AHF = 'ahf',
}

export enum ZCCPatientOutreachContactAttempt {
  FIRST = 'first',
  SECOND = 'second',
  FINAL = 'final',
}

export const ZCCPatientOutreachContactAttemptOptions = [
  { value: ZCCPatientOutreachContactAttempt.FIRST, label: 'First Call Made' },
  { value: ZCCPatientOutreachContactAttempt.SECOND, label: 'Second Call Made' },
  { value: ZCCPatientOutreachContactAttempt.FINAL, label: 'Final Attempt Made' },
];

export enum ZCCPatientOutreachContactOutcome {
  LEFT_VOICEMAIL = 'left voicemail',
  UNABLE_TO_LEAVE_VOICEMAIL = 'unable to leave voicemail',
  APPOINTMENT_REQUESTED = 'appointment requested',
  RETURNED_REFERRAL = 'returned referral',
  VETERAN_WAITING_TO_SCHEDULE = 'veteran waiting to schedule',
}

export const ZCCPatientOutreachContactOutcomeOptions = [
  { value: ZCCPatientOutreachContactOutcome.LEFT_VOICEMAIL, label: 'Left voicemail' },
  {
    value: ZCCPatientOutreachContactOutcome.UNABLE_TO_LEAVE_VOICEMAIL,
    label: 'Unable to leave voicemail',
  },
  {
    value: ZCCPatientOutreachContactOutcome.APPOINTMENT_REQUESTED,
    label: 'Appointment requested',
  },
  {
    value: ZCCPatientOutreachContactOutcome.RETURNED_REFERRAL,
    label: 'Returned referral',
  },
  /*
    {
    value: ZCCPatientOutreachContactOutcome.VETERAN_WAITING_TO_SCHEDULE,
    label: 'Veteran waiting to schedule',
  },
   */
];

export interface ZccApiNote {
  text: string;
  pcc_agent: string;
}

export interface ZccTaskType {
  _id: string;
  actions: string[];
  copied_to_hsrm: boolean;
  copied_to_hsrm_date: string;
  date: string;
  date_submitted: string;
  icd_10_codes: string[];
  impacted_area: string;
  milestone_appointment?: string;
  og_appointment_id?: string;
  patient_name: string;
  referral: string;
  va_referral_id: string;
  review_type: string;
  reviewed_by: string;
  reviewed_date: string;
  revocation_date: string;
  revocation_reason: string;
  session?: string;
  status: string;
  submitted_by: string;
  va_facility: string;
  va_facility_name: string;
  hold_until_date?: string;
}

export const fetchTasksItems = async (
  data: ZccApiParameters,
  cancelToken?: CancelToken
): Promise<{ items: Array<ZccTaskType>; total: number }> => {
  const response = await api.post({
    path: `/zcc/v1/task`,
    auth: true,
    data,
    cancelToken,
  });

  return {
    items: response?.results || [],
    total: response?.total,
  };
};

export interface ZccMedicalReviewType {
  _id: string;
  actions: string[];
  date_submitted?: string;
  first_submitted_date?: string;
  last_submitted_date?: string;
  treatment_date?: string;
  icd_10_codes: string[];
  impacted_area: any[];
  milestone_appointment?: string;
  og_appointment_id?: string;
  patient_name: string;
  referral: string;
  review_type: string;
  reviewed_by?: string;
  reviewed_date?: string;
  session?: string;
  status: string;
  submitted_by: string;
  submission_count?: number;
  va_facility?: string;
  va_facility_name?: string;
}

export const fetchMedicalReviewItems = async (
  data: ZccApiParameters,
  cancelToken?: CancelToken
): Promise<{ items: Array<ZccMedicalReviewType>; total: number }> => {
  const response = await api.post({
    path: `/zcc/v1/medical-review`,
    auth: true,
    data,
    cancelToken,
  });

  return {
    items: response?.results || [],
    total: response?.total,
  };
};

export enum ZccReferralStatus {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
  REVOKED = 'REVOKED',
  EXPIRED = 'EXPIRED',
  MEDICAL_HOLD = 'MEDICAL HOLD',
  SCHEDULING_HOLD = 'SCHEDULING HOLD',
  PATIENT_REVIEW_HOLD = 'TRUST AND SAFETY HOLD',
}

export interface ZccNote {
  pcc_agent: string;
  text: string;
  timestamp: string;
}

export interface ZccReferralType {
  _id: string;
  actions: string[];
  assigned_provider?: string;
  copied_to_hsrm: boolean;
  copied_to_hsrm_date: string;
  date_created: string;
  ingestion_date: string;
  expiration_date: string;
  icd_10_codes: any;
  impacted_area: string[];
  patient_id: string;
  patient_name: string;
  patient_dob: string;
  patient_ssn: string;
  patient_network_status: string;
  new_referral: boolean;
  remaining_sessions: number;
  reviewed_by: string;
  reviewed_date: string;
  revocation_date: string;
  revocation_reason: string;
  status: ZccReferralStatus;
  total_sessions: number;
  va_facility: string;
  va_facility_name: string;
  referring_organization: string;
  condition_type: string;
  va_referral_id: string;
  consents_completed: boolean;
  consents_missing: string[];
  main_note?: ZccNote;
  action_note?: ZccNote[];
  office_based_referral: boolean;
  hold_status_date?: string;
  hold_status_reason?: string;
  rfs_needed?: boolean;
  rfs_needed_reason?: string;
  rfs_submitted?: boolean;
}

export const fetchReferralItems = async (
  data: ZccApiParameters,
  cancelToken?: CancelToken
): Promise<{ items: Array<ZccReferralType>; total: number }> => {
  const response = await api.post({
    path: `/zcc/v1/referral`,
    auth: true,
    data,
    cancelToken,
  });

  return {
    items: response?.results || [],
    total: response?.total,
  };
};

export interface ZccPatientType {
  actions: string[];
  address: string;
  birth_date: string;
  gender: string;
  icn: string;
  network_status: string;
  og_member_id: string;
  patient_name: string;
  phone: string;
  ssn: string;
  zeel_id: string;
  _id: string;
  consents: Record<
    string,
    {
      consent_types: string[];
      consents_completed: boolean;
      consents_missing: string[];
    }
  >;
  main_note?: ZccNote;
  action_note?: ZccNote[];
  on_hold_date?: string;
  on_hold_reason?: string;
}

export const fetchPatientItems = async (
  data: ZccApiParameters,
  cancelToken?: CancelToken
): Promise<{ items: Array<ZccPatientType>; total: number }> => {
  const response = await api.post({
    path: `/zcc/v1/patient`,
    auth: true,
    data,
    cancelToken,
  });

  return {
    items: response?.results || [],
    total: response?.total,
  };
};

export const searchPatientName = async (name: string, searchTestDataOnly: boolean): Promise<string[]> => {
  const { items } = await fetchPatientItems({
    page: 0,
    pageSize: 20,
    filters: { patient_name: name, ...(searchTestDataOnly ? { test_data: true } : {}) },
    sortBy: {},
  });
  return items.map(({ patient_name }) => patient_name);
};

export const searchPatient = async (
  name: string,
  searchTestDataOnly: boolean
): Promise<
  {
    label: string;
    value: ZccPatientType;
  }[]
> => {
  const { items } = await fetchPatientItems({
    page: 0,
    pageSize: 20,
    filters: { patient_name: name, ...(searchTestDataOnly ? { test_data: true } : {}) },
    sortBy: {},
  });

  return items.map((item) => ({ label: item.patient_name, value: item }));
};

export enum ZccPractitionerNetworkStatus {
  RemovedFromNetwork = 'Removed from Network',
  ActiveInNetwork = 'Active in Network',
}

export interface ZccPractitionerOfficeAddressType {
  ada_compliant: boolean;
  address: string;
  office_note?: string;
  rejection_note?: string;
  reviewed_by: string;
  reviewed_date?: string;
  status: string;
  paused_note: string;
  paused_to_date?: string;
  closed_note?: string;
}

export interface ZccPractitionerType {
  actions: string[];
  active: boolean;
  address: string;
  email: string;
  gender: string;
  network_status: ZccPractitionerNetworkStatus;
  office_based_address: (string | ZccPractitionerOfficeAddressType)[];
  npi: string;
  og_member_id: string;
  og_provider_id: string;
  phone: string;
  practitioner_name: string;
  specialties: string;
  vaccinated_provider: boolean;
  validated_provider: boolean;
  zeel_id: string;
  _id: string;
  name: Array<{
    family: string;
    given: Array<string>;
  }>;
  telecom?: Array<{
    system: string;
    value?: string | number;
    use?: string;
    rank?: number;
  }>;
}

export type PractitionerAddressType = {
  mailing: {
    address: string;
    unitNumber: string;
    city: string;
    state: string;
    zip: string;
  };
  office: {
    address: string;
    unitNumber: string;
    city: string;
    state: string;
    zip: string;
    vaOfficeBasedLocation: boolean;
    availableOfficeHours: string;
  };
};

export type PractitionerQualificationType = {
  state: string;
  specialty: string;
  npi: string;
  taxonomyCode: string;
};

export type LinkedReferralType = {
  referralId: string;
  patient: ZccPatientType;
  expiration: string;
  appointment: AppointmentType;
};

export enum PractitionerAction {
  approveOrRemoveAsVAOfficeBasedLocation = 'Approve / Remove as VA Office-Based Location',
  approveForRemovedFromAutomatedSoapReview = 'Approve for / Removed from Automated Soap Review',
  approveForRemovedFromRfsReview = 'Approve for / Removed from RFS Review',
  approveForRemovedFromEvaluationReview = 'Approve for / Removed from Evaluation Review',
  removeFromRestoreToNetwork = 'Remove from / Restore to Network',
}

export type PractitionerType = {
  photo: any;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  nationalPractitionerIdentifier: string;
  networkStatus: string;
  practitionerId: string;
  recentAppointmentLocation: string;
  address: string;
  addressUnitNumber?: string;
  soapAutoApprove: 'On' | 'Off';
  officeName: string;
  officeTakingAppointments: 'On' | 'Off';
  officeAddress: string;
  officeAddressUnitNumber?: string;
  zeelMemberAccountId: string;
  comments?: any;
  appointments: [
    {
      patientName: string;
      appointmentId: string;
      appointmentDate: Date;
      appointmentStatus: string;
    }
  ];
  recordHistory?: any;
};

export const fetchPractitioners = async (
  data: ZccApiParameters,
  cancelToken?: CancelToken
): Promise<{ items: Array<ZccPractitionerType>; total: number }> => {
  const response = await api.post({
    path: `/zcc/v1/practitioner`,
    auth: true,
    data: { ...data },
    cancelToken,
  });

  return {
    items: response?.results || [],
    total: response?.total,
  };
};

export const searchPractitionerName = async (name: string): Promise<string[]> => {
  const { items } = await fetchPractitioners({
    page: 0,
    pageSize: 20,
    filters: { practitioner_name: name },
    sortBy: {},
  });
  return items.map(({ practitioner_name }) => practitioner_name);
};

export interface ZccAppointmentType {
  _id: string;
  actions: string[];
  copied_to_hsrm: boolean;
  copied_to_hsrm_date?: string;
  date?: string;
  created_on?: string;
  milestone_appointment: string;
  icd_10_codes: string[];
  impacted_area: string[];
  og_appointment_id: string;
  practitioner_og_member_id?: string;
  address: string;
  patient_name: string;
  patient_og_member_id?: string;
  practitioner_name: string;
  practitioner_npi: string;
  session: string;
  status: string;
  va_referral_id: string;
  va_facility: string;
  va_facility_name: string;
  og_stage: AppointmentStageType;
  main_note: ZccNote;
  action_note: ZccNote[];
  office_based: boolean;
  timezone?: string;
}

export const fetchAppointmentItems = async (
  data: ZccApiParameters,
  cancelToken?: CancelToken
): Promise<{ items: Array<ZccAppointmentType>; total: number }> => {
  const response = await api.post({
    path: `/zcc/v1/appointment`,
    auth: true,
    data,
    cancelToken,
  });

  return {
    items: response?.results || [],
    total: response?.total,
  };
};

export const fetchZccReferral = async (
  serviceRequestId: string
): Promise<{
  mainNote?: ZccNote;
  actionNote?: ZccNote[];
  rfsSubmitted?: boolean;
  assignedProvider?: string;
  previousPatientContact?: {
    contact_attempt: ZCCPatientOutreachContactAttempt;
    contact_outcome: ZCCPatientOutreachContactOutcome;
    timestamp: string;
  }[];
}> => {
  const response = await api.post({
    path: `/zcc/v1/referral`,
    auth: true,
    data: {
      filters: {
        _id: serviceRequestId,
      },
      page: 0,
      pageSize: 1,
    },
  });

  const { main_note, action_note, rfs_submitted, assigned_provider, previous_patient_contact } =
    response?.results?.[0] || {};

  return {
    mainNote: main_note,
    actionNote: action_note,
    rfsSubmitted: rfs_submitted,
    assignedProvider: assigned_provider,
    previousPatientContact: previous_patient_contact || [],
  };
};

export const fetchNotesFromPatient = async (
  patientId: string
): Promise<{ mainNote?: ZccNote; actionNote?: ZccNote[] }> => {
  const response = await api.post({
    path: `/zcc/v1/patient`,
    auth: true,
    data: {
      filters: {
        _id: patientId,
      },
      page: 0,
      pageSize: 1,
    },
  });

  const { main_note, action_note } = response?.results?.[0] || {};

  return {
    mainNote: main_note,
    actionNote: action_note,
  };
};

export const fetchNotesFromAppointment = async (
  apptId: string
): Promise<{ mainNote?: ZccNote; actionNote?: ZccNote[] }> => {
  const response = await api.post({
    path: `/zcc/v1/appointment`,
    auth: true,
    data: {
      filters: {
        _id: apptId,
      },
      page: 0,
      pageSize: 1,
    },
  });

  const { main_note, action_note } = response?.results?.[0] || {};

  return {
    mainNote: main_note,
    actionNote: action_note,
  };
};

export const createNote = async (note: {
  note: ZccApiNote | ZccApiNote[];
  noteType: 'main' | 'action';
  patientId: string;
}) => {
  const { note: textNote, noteType, patientId } = note;
  const response = await api.post({
    path: '/zcc/v1/note',
    auth: true,
    data: {
      note: textNote,
      note_type: noteType,
      patient_id: patientId,
    },
  });
  return response;
};

export const updateNote = async (note: {
  note: ZccApiNote | ZccApiNote[];
  noteType: 'main' | 'action';
  patientId: string;
}) => {
  const { note: textNote, noteType, patientId } = note;
  const response = await api.put({
    path: '/zcc/v1/note',
    auth: true,
    data: {
      note: textNote,
      note_type: noteType,
      patient_id: patientId,
    },
  });
  return response;
};

export interface ZccVAFacilityType {
  _id: string;
  address: string;
  external_note: string;
  external_note_agent: string;
  external_note_time: string;
  internal_note: string;
  internal_note_agent: string;
  internal_note_time: string;
  ng_location_id: string;
  phone: string;
  vamc_facility_id: string;
  vamc_name: string;
  vamc_state: string;
  insurer: string;
}

export const fetchVAFacilityItems = async (
  data: ZccApiParameters,
  cancelToken?: CancelToken
): Promise<{ items: Array<ZccVAFacilityType>; total: number }> => {
  const response = await api.post({
    path: `/zcc/v1/location`,
    auth: true,
    data,
    cancelToken,
  });

  return {
    items: response?.results || [],
    total: response?.total,
  };
};

export const searchVAFacilityName = async (name: string): Promise<string[]> => {
  const { items } = await fetchVAFacilityItems({
    page: 0,
    pageSize: 20,
    filters: { vamc_name: name },
    sortBy: {},
  });
  return items.map(({ vamc_name }) => vamc_name);
};

export const fetchAllVAFacilities = async (): Promise<Array<ZccVAFacilityType>> => {
  const response = await api.post({
    path: `/zcc/v1/location`,
    auth: true,
    data: { page: 0, pageSize: 10000 },
  });

  return response?.results || [];
};

export interface ZccEFaxType {
  _id: string;
  document_id: string;
  document_url: string;
  received_date: string;
  actions: string[];
  phone_from?: string;
  notes?: ZccNote[];
  processed: boolean;
  processed_by?: string;
  processed_date?: string;
}

export const fetchEFaxItems = async (
  data: ZccApiParameters,
  cancelToken?: CancelToken
): Promise<{ items: Array<ZccEFaxType>; total: number }> => {
  const response = await api.post({
    path: `/zcc/v1/efax/_search`,
    auth: true,
    data,
    cancelToken,
  });

  return {
    items: response?.results || [],
    total: response?.total,
  };
};

export const fetchEFax = async (eFaxId: string): Promise<ZccEFaxType> => {
  const response = await api.get({
    path: `/zcc/v1/efax/${eFaxId}`,
    auth: true,
  });

  return response;
};

export const updateEFax = async (eFaxId: string, data: Partial<ZccEFaxType>) => {
  const response = await api.patch({
    path: `/zcc/v1/efax/${eFaxId}`,
    auth: true,
    data,
  });

  return response;
};

export interface ZccRFSType {
  _id: string;
  actions: string[];
  document_url: string;
  patient_name: string;
  provider_name: string;
  referral: string;
  referral_count: number;
  referral_received: boolean;
  referral_received_date: string;
  referral_type: string;
  rfs_submitted_date: string;
  status: string;
  test_data: boolean;
  va_facility: string;
  va_facility_name: string;
  vamc_rejected: boolean;
  vamc_rejected_category: string;
  vamc_rejected_date: string;
  vamc_rejected_notes: string;
}

export const fetchRFSItems = async (
  data: ZccApiParameters,
  cancelToken?: CancelToken
): Promise<{ items: Array<ZccRFSType>; total: number }> => {
  const response = await api.post({
    path: `/zcc/v1/rfs`,
    auth: true,
    data,
    cancelToken,
  });

  return {
    items: response?.results || [],
    total: response?.total,
  };
};

/*
* "referral_id": "<ng_referral_id>",
        "contact_attempt": "first|second|final",
        "contact_outcome": "left voicemail|unable to leave voicemail|appointment requested|returned referral"
      }
*/
export const recordPatientOutreachContact = async (data: {
  referralId: string;
  contactAttempt: ZCCPatientOutreachContactAttempt;
  contactOutcome: ZCCPatientOutreachContactOutcome;
}) => {
  const response = await api.post({
    path: '/zcc/v1/patient-contact',
    auth: true,
    data: {
      ng_referral_id: data.referralId,
      contact_attempt: data.contactAttempt,
      contact_outcome: data.contactOutcome,
    },
  });
  return response;
};

export const copyPatientOutreachContactToHsrm = async (taskId: string) => {
  const response = await api.patch({
    path: `/zcc/v1/patient-contact/${taskId}`,
    auth: true,
    data: {
      copied_to_hsrm: true,
    },
  });
  return response;
};
